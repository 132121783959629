jQuery(document).ready(function($) {

  // COMMON COMPONENTS
  // Image Lightbox
  if ($('[class*="wp-image-"]').length) {
    $('[class*="wp-image-"]').each(function(i) {
      if (($(this).parent().is('a')) && ($(this).parent().attr('target') != '_blank')) {
        $(this).parent().attr('data-uk-lightbox', '');
      }
    });
  }

  // Image Gallery
  if ($('.gallery').length) {
    $('.gallery').each(function(i) {
      var galleryID = $(this).attr('id');

      $(this).find('.gallery-item a').each(function(j) {
        $(this).attr('data-uk-lightbox', '{group:\'' + galleryID + '\'}');
      });
    });
  }

  // Table
  if ($('table').length) {
    $('table').addClass('uk-table uk-table-striped').wrap('<div class="uk-overflow-container"></div>');
  }

  // Video
  if ($('iframe').length) {
    $('iframe').each(function() {
      if ($(this).parent().is('p')) {
        $(this).unwrap().wrap('<div class="video-wrapper"></div>');
      }
    });
  }

  // Construction number ticker
  // if ($('.gs-stat-construction').length) {
  //   var conNumber = $('.gs-stat-construction h1');
  //   var exp = /^(.*[\\\,])/;
  //   var prefix = exp.exec(conNumber.text())[0];
  //   var trail = 0;
    
  //   function gsFooterTick() {
  //     var interval = Math.floor( Math.random() * 1000 );

  //     setTimeout(function() {
  //       var number = Math.floor( Math.random() * 10 );
  //       if ( trail + number <= 999 ) {
  //         trail += number;

  //         var trailString = ('00' + trail).slice(-3);
  //         conNumber.text(prefix+trailString);
  //         gsFooterTick();
  //       }
  //     }, interval);
  //   }
  // }

  // gsFooterTick();

  // Front page animation

  // function animationInit(sprite, trigger) {

  //   if ($(sprite).length) {
  //     var sprite = $(sprite);
  //     var trigger = $(trigger);
  //     var frame = 12;
  //     var maxPercent = 100 - (100 / frame);
  //     var speed = 0.3;

  //     var tween = TweenMax.to(sprite, speed, {
  //       yPercent: -maxPercent,
  //       ease: SteppedEase.config(frame - 1),
  //     });

  //     tween.pause();

  //     trigger.hover(function() {
  //       tween.play();
  //     }, function() {
  //       tween.reverse();
  //     });
  //   }

  // };

  // animationInit('.gs-home-pillar-render-sprite.cw', '.gs-home-pillar-card.curtain-walling');
  // animationInit('.gs-home-pillar-render-sprite.rc', '.gs-home-pillar-card.roof-cladding');
  // animationInit('.gs-home-pillar-render-sprite.sp', '.gs-home-pillar-card.special-projects');

  // Our Portfolio Vue
  if ($('#gs-port').length >= 1) {
    var number = 12; // post number per page
    var filterToggle = $('.gs-port-filter-toggle'); // filter Toggle
    var port = new Vue({
      el: '#gs-port',
      data: {
        projects: [],
        projectsDisplay: [],
        projectsCount: 0,
        paginationInfo: [], // currentPage, totalPage
        paginationInfoDisplay: [],
        pillars: [],
        descending: 1,
        tags: [],
        loadState: 0,
        projectsCountState: 0,
      },
      methods: {
        projectFetch: function(callback) {
          var order = (port.descending ? 'desc' : 'asc');
          var tags = [];

          for (var i = port.tags.length - 1; i >= 0; i--) {
            if (port.tags[i]['state'] === 1) {
              tags.push(port.tags[i]['id']);
            }
          }

          if (tags.length === 0) {
            var url = '/wp-json/wp/v2/project?per_page=' + number + '&order=' + order;
          } else {
            var url = '/wp-json/wp/v2/project?per_page=' + number + '&order=' + order + '&filter[readable_tag]=' + tags.join(",");
          }

          $.ajax({
            url: url,
          }).done(function(data, status, request) {
            // console.log(data);
            port.projects = data;
            port.projectsCount = request.getResponseHeader('X-WP-Total');
            port.paginationInfo = [];
            port.paginationInfo.push(1);
            port.paginationInfo.push(request.getResponseHeader('X-WP-TotalPages'));

            port.projectsCountState = 0; // Show project count

            if (callback) {
              callback();
            }
          });

        },
        tagFetch: function() {
          var url = '/wp-json/wp/v2/read_tag?per_page=100';

          $.ajax({
            url: url,
          }).done(function(data, status, request) {
            for (var i = data.length - 1; i >= 0; i--) {
              var tagData = {};
              
              tagData['id'] = data[i]['id'];
              tagData['name'] = data[i]['name'];
              tagData['state'] = 0;

              port.tags.push(tagData);
            }
          });

        },
        orderToggle: function(state) {
          port.descending = state;
          port.projectFetch( port.displayProjects );
        },
        tagModify: function(id) {
          port.projectsCountState = 1;
          
          var tags = port.tags;
          
          for (var i = tags.length - 1; i >= 0; i--) {
            if (tags[i]['id'] === id) {
              if (tags[i]['state'] === 0) {
                tags[i]['state'] = 1;
              } else {
                tags[i]['state'] = 0;
              }
            }
          }

          port.projectFetch();
        },
        tagReset: function() {
          var tags = port.tags;

          for (var i = tags.length - 1; i >= 0; i--) {
            tags[i]['state'] = 0;
          }

          port.projectFetch( port.displayProjects );
          
          // close filter box
          // $('.gs-port-filter-details, .gs-port-filter-toggle, .gs-port-projects').removeClass('uk-active');

          // console.log(filterToggle.hasClass());
        },
        projectLoad: function() {
          var order = (port.descending ? 'desc' : 'asc');
          var tags = [];
          var currentPage = port.paginationInfoDisplay[0];

          for (var i = port.tags.length - 1; i >= 0; i--) {
            if (port.tags[i]['state'] === 1) {
              tags.push(port.tags[i]['id']);
            }
          }

          if (tags.length === 0) {
            var url = '/wp-json/wp/v2/project?per_page=' + number + '&page=' + (currentPage + 1) + '&order=' + order;
          } else {
            var url = '/wp-json/wp/v2/project?per_page=' + number + '&page=' + (currentPage + 1) + '&order=' + order + '&filter[readable_tag]=' + tags.join(",");
          }

          port.loadState = 1;

          $.ajax({
            url: url,
          }).done(function(data, status, request) {
            // console.log(data);
            var newProjects = data;

            port.loadState = 0;

            for (var i = 0; i < newProjects.length; i++) {
              port.projects.push(newProjects[i]);
            }

            port.paginationInfo.splice(0, 1, currentPage + 1);

            // console.log(port.projectsDisplay);
            // console.log(port.paginationInfoDisplay);
          });

        },
        displayProjects: function() {
          port.projectsDisplay = port.projects;
          port.paginationInfoDisplay = port.paginationInfo;

          // close filter box
          $('.gs-port-filter-details, .gs-port-filter-toggle, .gs-port-projects').removeClass('uk-active');
        },
      },
      mounted: function() {
        // fetch projects
        this.$nextTick(function() {
         this.projectFetch( port.displayProjects );
         this.tagFetch();
        });
      },
      updated: function() {
        $('.gs-port-projects .uk-grid').trigger('display.uk.check');
      },
    });
  }

  // Our Capabilities filter
  if ($('#gs-cap-location-info').length >= 1) {
    var buttons = $('.gs-cap-location-info-toggle');
    var activeType = $('.gs-cap-location-info-toggle.gs-active').data('type');
    
    activateLocations(activeType);
    
    buttons.click(function(e) {
      e.preventDefault();

      var button = $(this);
      
      buttons.removeClass('gs-active'); // Restart the styles
      
      button.addClass('gs-active');
      activeType = button.data('type');

      activateLocations(activeType);
    });

    function activateLocations(id) {
      var locations = $('.gs-cap-location-info-location');

      locations.removeClass('gs-active'); // Restart the styles

      for (var i = locations.length - 1; i >= 0; i--) {
        var location = $(locations[i]);
        var locationType = location.data('location-type');

        if (locationType.indexOf(id) > -1 ) {
          location.addClass('gs-active');
        }
      }
    }
  };

  // Projects P&R Toggle Button
  if ($('.gs-project-pr-toggle_button').length >= 1) {
    var button = $('.gs-project-pr-toggle_button');
    var toOpen = button.data('to-open');
    var toClose = button.data('to-close');

    button.click(function() {
      if (button.hasClass('uk-active')) {
        // Change to toOpen
        button.text(toOpen);
      } else {
        // Change to toClose
        button.text(toClose);
      }
    });
  }
});
